import { metaAdvancedReporting, metaBulkManager, metaCallLogs } from 'apps/meta';
import { ADD_KEY, DESKTOP_APP_DEFAULT_ZONE, REGEX } from 'constant';
import i18next from 'i18next';
import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import {
  I18NEXT_OPTIONS,
  NotificationToType,
  NotificationType,
} from '../components/section/NotificationsSection';
import { TEMP_PROPERTY, TEMP_PROPERTY_TRANSFORMERS } from '../constant';
import { OnAccountSubmitSuccessParams } from '../definition';

/**
 * @name aggregateData
 * @description Aggregate various (fetched) data objects into one object.
 *              TODO: Reimplement as a custom hook.
 *
 * @param [object]
 * @property accountId
 * @property parentId
 * @property data
 * @property hook
 */
export const aggregateData = ({
  accountId,
  parentId,
  data,
  hook,
}: {
  accountId: string;
  parentId: string;
  data: any;
  hook: any;
}) => {
  ({ data: data.account } = hook.useFetchAccountByIdQuery(
    {
      id: accountId,
    },
    { skip: !accountId || accountId === ADD_KEY || data.account },
  ));

  ({ data: data.accountHeroApps } = hook.useFetchAccountHeroAppsQuery(
    {
      id: accountId === ADD_KEY ? parentId : accountId,
    },
    { skip: !accountId || data.accountHeroApps },
  ));

  ({ data: data.heroConfig } = hook.useFetchAccountDesktopAppZoneQuery(
    {
      id: accountId,
    },
    { skip: !accountId || data.heroConfig },
  ));

  ({ data: data.appsStoreAccount } = hook.useFetchAppsQuery(
    { accountId: accountId === ADD_KEY ? parentId : accountId },
    { skip: !accountId || data.appsStoreAccount },
  ));

  ({ data: data.appsStoreCurrentAccount } = hook.useFetchAppsQuery(
    {},
    { skip: !accountId || data.appsStoreCurrentAccount },
  ));

  // REMARK: Keep here until further investigate
  // !data['user'] && ({ data: data['user'], isSuccess: isSuccess['user'] } = hook.useFetchUsersQuery({ accountId: id }));

  data.notification = {};

  ({
    data: data.notification[NotificationType.VoicemailToEmail],
  } = hook.useFetchNotificationByIdQuery(
    {
      accountId,
      typeId: NotificationType.VoicemailToEmail,
    },
    {
      skip:
        !accountId || accountId === ADD_KEY || data.notification[NotificationType.VoicemailToEmail],
    },
  ));

  ({
    data: data.notification[NotificationType.FaxInboundErrorToEmail],
  } = hook.useFetchNotificationByIdQuery(
    {
      accountId,
      typeId: NotificationType.FaxInboundErrorToEmail,
    },
    {
      skip:
        !accountId ||
        accountId === ADD_KEY ||
        data.notification[NotificationType.FaxInboundErrorToEmail],
    },
  ));

  ({ data: data.notification[NotificationType.Deregister] } = hook.useFetchNotificationByIdQuery(
    {
      accountId: accountId === ADD_KEY ? parentId : accountId,
      typeId: NotificationType.Deregister,
    },
    { skip: !accountId || accountId === ADD_KEY || data.notification[NotificationType.Deregister] },
  ));

  ({ data: data.notification[NotificationType.SeatCreated] } = hook.useFetchNotificationByIdQuery(
    {
      accountId: accountId === ADD_KEY ? parentId : accountId,
      typeId: NotificationType.SeatCreated,
    },
    {
      skip: !accountId || accountId === ADD_KEY || data.notification[NotificationType.SeatCreated],
    },
  ));

  ({
    data: data.notification[NotificationType.SeatDeactivated],
  } = hook.useFetchNotificationByIdQuery(
    {
      accountId: accountId === ADD_KEY ? parentId : accountId,
      typeId: NotificationType.SeatDeactivated,
    },
    {
      skip:
        !accountId || accountId === ADD_KEY || data.notification[NotificationType.SeatDeactivated],
    },
  ));
};

/**
 * @name deleteTemporaryProperties
 * @description Delete temporary form properties.
 *
 * @param formData
 *
 * @returns Data object.
 */
export const deleteTemporaryProperties = (formData: any): any =>
  produce(formData, (draft: any) => {
    Object.values(TEMP_PROPERTY).forEach((property: string) => {
      delete draft[property];
    });
  });

/**
 * @name filterForAdmins
 * @description Keep only items with `priv_level` value of "admin".
 *
 * @param data
 *
 * @returns Data object.
 */
export const filterForAdmins = (data: any) =>
  data?.filter((item: any) => item.priv_level === 'admin');

/**
 * @name getDesktopDefaultAppZone
 * @description Determine the desktop default app zone.
 *
 * @param data
 *
 * @returns Desktop app zone.
 */
export const getDesktopDefaultAppZone = (data: any) => {
  const desktopAppZone = data?.[TEMP_PROPERTY.DESKTOP_APP_ZONE];

  if (!desktopAppZone || desktopAppZone === DESKTOP_APP_DEFAULT_ZONE) {
    return null;
  }

  return desktopAppZone;
};

/**
 * @name normalizeNameSegment
 * @description Prepare text to be used to autofill field value:
 *              • Normalize unicode characters
 *              • Remove problematic unicode characters
 *              • Remove non-alphanumeric characters
 *              • Convert value to lowercase
 *
 * @param [name='']
 *
 * @returns Normalized string segment
 */
export const normalizeNameSegment = (name = '') =>
  name
    .normalize('NFD')
    .replace(REGEX.ACCOUNT.REALM.SEGMENT.REPLACE_CHARS.UNICODE, '')
    .replace(REGEX.ACCOUNT.REALM.SEGMENT.REPLACE_CHARS.STANDARD, '')
    .toLowerCase();

/**
 * @name onAccountSubmitSuccess
 * @description On account submit success, call the `handleSaveSuccess` function.
 *
 * @param [object]
 * @param isAdd
 * @param handleSaveSuccess
 */
export const onAccountSubmitSuccess = ({
  isAdd,
  handleSaveSuccess,
}: OnAccountSubmitSuccessParams) => {
  handleSaveSuccess({ hasToast: !isAdd, shouldRedirect: isAdd });
};

/**
 * @name transformAccountData
 * @description Transform form data to fit in API schema.
 *
 * @param accountData
 *
 * @returns Data object.
 */
export const transformAccountData = (accountData: any) =>
  produce(accountData, (draft: any) => {
    Object.entries(TEMP_PROPERTY_TRANSFORMERS).forEach(
      ([tempPropertyName, { toFieldName, transformer }]) => {
        const data = draft[tempPropertyName];
        draft[toFieldName] = transformer(data);
      },
    );

    if (!draft.language || draft.language === 'auto') {
      delete draft.language;
    }
  });

/**
 * @name transformAccountDataToFormState
 * @description Transform api account data to form state.
 *
 * @param data
 *
 * @returns Data object.
 */
export const transformAccountDataToFormState = (data: any): Record<string, any> =>
  produce(data, (draft: any) => {
    if (draft) {
      if (!draft.language) {
        draft.language = 'auto';
      }
    }
  });

/**
 * @name transformNotificationDataToFormState
 * @description Transform api notification data to form state.
 *
 * @param data
 *
 * @returns Data object.
 */
export const transformNotificationDataToFormState = (data: any): Record<string, any> =>
  produce(data, (draft: any) => {
    if (draft) {
      [NotificationType.Deregister, NotificationType.SeatCreated].forEach((type: string) => {
        if (data[type]) {
          draft[type].send = { email_addresses: [], type: {} };

          if (
            data[type].to?.type === NotificationToType.Admins ||
            data[type].to?.type === NotificationToType.AccountAdmins
          ) {
            draft[type].send.type[NotificationToType.AccountAdmins] = true;
          }

          if (data[type].to?.type === NotificationToType.Specified) {
            draft[type].send.email_addresses = data[type].to.email_addresses;
            draft[type].send.type[NotificationToType.Specified] = true;
          }

          if (data[type].cc?.type === NotificationToType.Specified) {
            draft[type].send.email_addresses = data[type].cc.email_addresses;
            draft[type].send.type[NotificationToType.Specified] = true;
          }
        }
      });
    }
  });

/**
 * @name updateSection
 * @description Object containing `appExchange`, `desktopModules`, `desktopAppZone`,
 *              and `notifications` functions for updating their respective sections.
 */
export const updateSection = {
  appExchange: async ({
    id,
    apps,
    data,
    mutation,
  }: {
    id: string;
    apps: Array<string>;
    data: any;
    mutation: any;
  }): Promise<any> => {
    const enabledApps = Object.entries(data[TEMP_PROPERTY.IS_IN_APPS_STORE])
      .map(([appId, isEnabled]: Array<any>) => isEnabled && appId)
      .filter((appId: string) => !!appId);

    const blacklist = apps
      .map(({ id }: any) => id)
      .filter((appId: string) => !enabledApps.includes(appId));

    await mutation({ accountId: id, body: { blacklist } });
  },

  desktopModules: async ({
    id,
    data,
    mutation,
  }: {
    id: string;
    data: any;
    mutation: any;
  }): Promise<any> => {
    await mutation({ id, body: { apps: data[TEMP_PROPERTY.HERO_APPS] } });
  },

  desktopAppZone: async ({
    id,
    data,
    dirtyFields,
    heroConfigData,
    mutation,
  }: {
    id: string;
    data: any;
    dirtyFields: any;
    heroConfigData: any;
    mutation: any;
  }): Promise<any> => {
    if (dirtyFields[TEMP_PROPERTY.DESKTOP_APP_ZONE]) {
      const newBody = heroConfigData ? cloneDeep(heroConfigData) : {};

      newBody.default_zone = getDesktopDefaultAppZone(data);

      await mutation({
        id,
        body: newBody,
      });
    }
  },

  notifications: async ({
    id: accountId,
    data,
    dirtyFields,
    mutation,
  }: {
    id: string;
    data: any;
    dirtyFields: any;
    mutation: any;
  }): Promise<any> => {
    await Promise.all(
      Object.values(NotificationType).map(
        async (typeId: string): Promise<any> => {
          const id =
            typeId !== NotificationType.SeatDeactivated ? typeId : NotificationType.SeatCreated;

          if (dirtyFields[TEMP_PROPERTY.NOTIFICATIONS]?.[id]) {
            const templateData = data[TEMP_PROPERTY.NOTIFICATIONS][id];
            const body = produce(templateData, (draft: any) => {
              if (typeId === NotificationType.SeatDeactivated) {
                const seatCreatedBody =
                  data[TEMP_PROPERTY.NOTIFICATIONS][NotificationType.SeatCreated];

                if (seatCreatedBody) {
                  draft.enabled = seatCreatedBody.enabled;
                  draft.friendly_name = i18next.t(
                    'accounts_manager:containers.accounts.section.notifications.template.seat_deactivated.friendly_name',
                  );
                  draft.from = seatCreatedBody.from;
                  draft.send = seatCreatedBody.send;
                  draft.subject = i18next.t(
                    'accounts_manager:containers.accounts.section.notifications.template.seat_deactivated.subject',
                    I18NEXT_OPTIONS,
                  );
                }
              }

              if (
                typeId === NotificationType.Deregister ||
                typeId === NotificationType.SeatCreated ||
                typeId === NotificationType.SeatDeactivated
              ) {
                // always reset
                draft.to = {
                  type: NotificationToType.AccountAdmins,
                };
                draft.cc = {
                  email_addresses: [],
                  type: NotificationToType.Original,
                };

                if (templateData.enabled && templateData.send) {
                  const isAdmins = templateData.send.type[NotificationToType.AccountAdmins];
                  const isSpecified = templateData.send.type[NotificationToType.Specified];
                  const emailAddresses = [...templateData.send.email_addresses];

                  if (isAdmins && !isSpecified) {
                    draft.to = {
                      type: NotificationToType.AccountAdmins,
                    };
                  }

                  if (!isAdmins && isSpecified) {
                    draft.to = {
                      email_addresses: emailAddresses,
                      type: NotificationToType.Specified,
                    };
                  }

                  if (isAdmins && isSpecified) {
                    draft.to = {
                      type: NotificationToType.AccountAdmins,
                    };
                    draft.cc = {
                      email_addresses: emailAddresses,
                      type: NotificationToType.Specified,
                    };
                  }
                }

                // always delete
                delete draft.send;
              }
            });

            await mutation({ accountId, typeId, body });
          }
        },
      ),
    );
  },
};

export const overrideAppMeta = (i18n: any, appsStore?: any) =>
  appsStore?.length
    ? produce(appsStore, (draft: any) => {
        // Must be defined inside a function to avoid race condition of loading this utility file and meta file.
        const overrideAppMetaMap = {
          [metaAdvancedReporting.name]: {
            meta: metaAdvancedReporting,
            override(app: any) {
              app.i18n[i18n.language].label = this.meta.i18n.label;
            },
          },
          [metaBulkManager.name]: {
            meta: metaBulkManager,
            override(app: any) {
              app.phase = 'gold';
            },
          },
          [metaCallLogs.name]: {
            meta: metaCallLogs,
            override(app: any) {
              app.i18n[i18n.language].label = this.meta.i18n.label;
              app.phase = 'gold';
            },
          },
        };
        draft.forEach((app: any) => {
          overrideAppMetaMap[app.name]?.override(app);
        });
      })
    : appsStore;
