import { useFetchAccountExternalAppsQuery } from 'models/Account';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Error, { ErrorType } from 'shared/components/Error';
import { Iframe } from 'shared/components/Iframe';
import Page from '../Page';
import { IframeAppProps as Props } from './definition';
import { StyledIframeApp } from './style';
import { getUrl } from './utility';

export const IframeApp = ({ label, title }: Props) => {
  const { data } = useFetchAccountExternalAppsQuery();
  const [url, setUrl] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();
  const hasError = Boolean(errorMessage);

  useEffect(() => {
    if (data) {
      const finalUrl = getUrl(data.external_apps[title]?.url);
      if (finalUrl) {
        setUrl(finalUrl);
      } else {
        setErrorMessage(t('common:iframe.error', { label }));
      }
    }
  }, [data, title, label, t]);

  return (
    <Page label={label}>
      <StyledIframeApp $hasError={hasError.toString()}>
        {hasError ? (
          <Error message={errorMessage} type={ErrorType.Iframe} />
        ) : (
          <Iframe title={title} url={url} />
        )}
      </StyledIframeApp>
    </Page>
  );
};
