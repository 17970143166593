import { AsyncStatus } from 'definition';

export const HOURS_IN_DAY = 24;
export const MILLISECONDS_IN_SECOND = 1000;
export const MINUTES_IN_HOUR = 60;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTE;
export const SECONDS_IN_DAY = SECONDS_IN_HOUR * HOURS_IN_DAY;

export const ACCOUNT_ID_URL_PLACEHOLDER = '__account__';
export const ADD_KEY = 'add';
export const CALLER_ID_MAX_LENGTH = 35;
export const DEFAULT_APP = 'phone-system';
export const DEFAULT_TIMEZONE_OPTION_VALUE = 'inherit';
export const DEFAULT_EMAIL_ADDRESS = 'no_reply@voxter.com';
export const HASH_KEY = '#';
export const POLLING_INTERVAL = 3 * MILLISECONDS_IN_SECOND; // 3 seconds
export const REMOVE_KEY = 'remove';
export const SECONDS_GREGORIAN_OFFSET = 62167219200;
export const STAR_KEY = '*';
export const URL_PARAM_MASQUERADING_ACCOUNT_KEY = 'm';

export const TRANSLATION_NAMESPACE = Object.freeze([
  'common',
  'accounts_manager',
  'advanced_provisioner',
  'call_center',
  'numbers',
  'phone_system',
  'sip_trunking',
]);

export const ASYNC_BASE_STATE = Object.freeze({
  isError: false,
  isLoading: false,
  isSuccess: false,
  error: null,
  status: AsyncStatus.Idle,
});

export const CHARACTER = Object.freeze({
  DOT: '•',
  EMDASH: '—',
  PLUS: '+',
});

export const FORMAT = Object.freeze({
  DATE: 'MM/dd/yyyy',
  START_DATE: 'dd/MM/yy',
  STATUS_DATE: 'EEEE MMMM d, haaa',
  TIME: {
    HOUR: "haaaaa'm'",
    HOUR_MINUTES: "h:mmaaaaa'm'",
  },
  TIMESTAMP: "yyyy-MM-dd'T'HH:mm:ssxxx",
});

export const IS_ENV = Object.freeze({
  PRODUCTION: ['production'].includes(process.env.NODE_ENV),
  TEST: ['test'].includes(process.env.NODE_ENV),
});

export const JOI = Object.freeze({
  EMAIL: { tlds: { allow: false } },
});

export const LOCAL_STORAGE = Object.freeze({
  ANNOUNCEMENT: {
    IS_READ: 'is_announcement_read',
  },
  AUTH: {
    ACCOUNT_NAME: 'auth_account_name',
    ACCOUNT_ID: 'auth_account_id',
    USER_ID: 'auth_user_id',
    USERNAME: 'auth_username',
  },
  E911_NOTIFICATION: 'e911_notification_dialog',
  HIDE: {
    ONLINE_AGENTS: 'hide_online_agents',
  },
  IAM: {
    ACCESS_TOKEN: 'iam_access_token',
    REFRESH_TOKEN: 'iam_refresh_token',
    REGISTRATION_ID: 'iam_registration_id',
  },
  SHOW: {
    QUEUES: 'show_queues',
  },
  TOKEN: 'token',
  BIZPROV: {
    AUTH_TOKEN: 'bizprov_auth_token',
  },
});

export const MEDIA_TYPE = Object.freeze({
  CHARSET: {
    UTF8: ';charset=utf-8',
  },
  TYPE: {
    TEXT: {
      CSV: {
        DEFAULT: 'text/csv',
        get WITH_CHARSET() {
          return `${MEDIA_TYPE.TYPE.TEXT.CSV.DEFAULT}${MEDIA_TYPE.CHARSET.UTF8}`;
        },
      },
      PLAIN: {
        DEFAULT: 'text/plain',
        get WITH_CHARSET() {
          return `${MEDIA_TYPE.TYPE.TEXT.PLAIN.DEFAULT}${MEDIA_TYPE.CHARSET.UTF8}`;
        },
      },
    },
  },
});

export const REGEX = Object.freeze({
  ACCOUNT: {
    NAME: /^[a-zA-Z_][a-zA-Z0-9\s\-_]+$/,
    REALM: {
      DEFAULT: /^([a-z0-9-]+\.)+([a-z0-9-]+){1,1}$/, // /^[.\\w_-]+$/
      SEGMENT: {
        REPLACE_CHARS: {
          STANDARD: /[^a-zA-Z0-9-]/g,
          UNICODE: /[\u0300-\u036f]/g,
        },
      },
    },
  },
  CALLFLOW: {
    EXTENSION: /^[a-z0-9]+$/i,
  },
  FAXBOX: {
    SMTP_PERMISSION_LIST: /(?:[\w.-]+)?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/, // email or @domain format
  },
  ID_128_BIT: /[a-fA-F0-9_]{32}/,
  MAC_ADDRESS: /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/,
  MEMBER_PIN: /^[0-9]+$/,
  PHONE_NUMBER: /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$|^$|^[+]$/, // valid phone number (e.g., 1 (800) 555 5555)
  STRING: {
    IP_V4: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
    NUMERIC: /^\d+$/,
    SPECIAL_CHARACTERS: /[^a-zA-Z0-9]/g,
    USERNAME: /^\+?\d*$/,
  },
  VOICEMAIL_PIN: /^\d{4,6}$/,
  IP_OR_FQDN: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/,
});

// would be better if not hardcoded
export const SEAT_TYPE: Record<
  string,
  { configuration: Array<string>; id: string }
> = Object.freeze({
  admin: {
    configuration: ['seat'],
    id: '4b718fe2-6d36-11ea-bc55-0242ac130003',
  },
  MS_TEAMS: {
    configuration: [], // empty array it will show all the horizontal tabs
    id: '30703d89-a08d-450b-8e4d-13e96457c2b5',
  },
});

export const SESSION_STORAGE = Object.freeze({
  CALL_CENTER_CALL_QUEUES: 'call_center_call_queues',
  TABLE: {
    FILTER: 'table_filter',
    PAGINATION: {
      INDEX: 'table_pagination_index',
      SIZE: 'table_pagination_size',
    },
    SORT: {
      COLUMN: 'table_sort_column',
    },
  },
});

export const TEST_MOCK = Object.freeze({
  DRAFTJS_RANDOM_KEY: '1111',
  UUID: '00000000-0000-0000-0000-000000000000',
});

export const VALIDATION = Object.freeze({
  ACCOUNT: {
    NAME: {
      MAX: 128,
      MIN: 3,
      REGEX: REGEX.ACCOUNT.NAME,
    },
    REALM: {
      MAX: 253,
      MIN: 4,
      REGEX: REGEX.ACCOUNT.REALM.DEFAULT,
    },
  },
  MENU: {
    TIMEOUT: {
      MAX: 10000,
      MIN: 1000,
    },
    EXTENSION_NUMBER_LENGTH: {
      MIN: 1,
      MAX: 8,
    },
  },
  VOICEMAIL: {
    PIN: {
      MAX: 6,
      MIN: 4,
      REGEX: REGEX.VOICEMAIL_PIN,
    },
  },
});

export const URL = Object.freeze({
  EXTERNAL: {
    ADVANCED_PROVISIONER: `${window?.nemo?.api?.advancedProvisioner || ''}`,
    BIZPROV: `${window?.nemo?.api?.bizProv || ''}`,
  },
  STATUS: {
    BASE: 'https://9fyp311j4qs3.statuspage.io/api/v2',
    get SUMMARY() {
      return `${this.BASE}/summary.json`;
    },
  },
  SUPPORT: {
    BASE: 'https://support.oomaenterprise.com/support',
    get HOME() {
      return `${this.BASE}/home`;
    },
    get NEW_TICKET() {
      return `${this.BASE}/tickets/new`;
    },
  },
});

export const DESKTOP_APP_DEFAULT_ZONE = IS_ENV.PRODUCTION ? 'van1' : 'stg1';
